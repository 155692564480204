import './Throbber.css';

function Throbber()
{
    const rayon = 15;
    const delta = 2 * Math.PI / 8;
    const size = 50;
    const halfSize = size / 2;
    const circleList = [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>].map((_, index) => <circle key={index} r={1 + index * 2.0 / 8.0}  cx={halfSize + rayon * Math.cos(index * delta)} cy={halfSize + rayon * Math.sin(index * delta)} fill={'rgb(0, 0, 0,' + (index + 0.1) * 0.9 / 8.0 + ')'}/>)
    return (
    <svg className='throbber'height={size} width={size} xmlns="http://www.w3.org/2000/svg">
      {circleList}
    </svg>);
}
export default Throbber;