import './Contact.css';
import { useFormData } from 'herotofu-react';
import { FaTwitter, FaLinkedin, FaTiktok  } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import Throbber from './Throbber.js';

function Contact()
{
    const { formState, getFormSubmitHandler } = useFormData('https://public.herotofu.com/v1/389b2ab0-a047-11ef-a0ee-650b92707733');
    let optional_message = undefined;
    let contact;
    if(formState.status === "success")
      {
        optional_message = 
        <p style={{textAlign:"center"}}><h4 > Thank you for reaching out!</h4> Your message has been successfully sent. I’ll get back to you as soon as possible</p>
        contact = <>
        <button className='clickable_contact' type="submit">
          
        <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                      <rect
                      rx="5"
                      class="line"
                      height="100%"
                      width="100%"
                      stroke-linejoin="round"
                      />
                  </svg>
                  Send</button>
        </>
      
      }
      else if (formState.status === "loading")
        {
          contact = <Throbber />
      }
      else if (formState.status === "error")
        {
          contact = <p style={{textAlign:"center"}}><h4> Oops! Something went wrong.</h4>
We couldn't send your message due to a technical issue. Please try again later or contact me directly via email by clicking the button below. I look forward to hearing from you! </p>

      }
      else
      {
        contact = <button className='clickable_contact' type="submit">
          
        <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" overflow="visible">
                      <rect
                      rx="5"
                      class="line"
                      height="100%"
                      width="100%"
                      stroke-linejoin="round"
                      />
                  </svg>
                  Send</button>
        
      }
    /* not_initialized loading success => if (success) display ("Merci pour le message je vais vous reponde bientot") */
    return(
        <div className='contact content'>
          <svg width="0" height="0">
                <radialGradient id="icon-gradient" cx="106.57" cy="90.226" r="50" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
                <radialGradient id="icon-gradient2" cx="56.57" cy="10.226" r="300" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
            </svg>
          <h1>Contact</h1>
          <form onSubmit={getFormSubmitHandler()}>
            <div>
              <p>Name</p>
              <input type="text" placeholder="Your name" name="name" id="name" required />
            </div>
            <div>
              <p>Mail</p>
              <input type="mail" placeholder="Your mail" name="email" id="email" required />
            </div>
            <div>
              <p>Message</p>
              <textarea placeholder="Your message" name="message"  id="message" required />
            </div>
            <div className='center_div'>
              {contact}
              </div>
              {optional_message}
            <div className='quick_contact2'>
              <a href="mailto:sergio@drealight.com"><MdMailOutline style={{ fill: "url(#icon-gradient)"}}/></a>
              <a href="https://x.com/blightbubbles"><FaTwitter style={{ fill: "url(#icon-gradient2)"}}/></a> 
              <a href="https://de.linkedin.com/in/sergio-baudino-6a2874140"><FaLinkedin  style={{ fill: "url(#icon-gradient2)" }} /></a> 
              <a href="https://www.tiktok.com/@drealightgames"><FaTiktok style={{ fill: "url(#icon-gradient2)" }} /></a>
            </div>
          </form>
        </div>
    );
}

export default Contact;